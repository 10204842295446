.VacationList {
	margin: auto;
	height: 100%;
}

.pagination {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 5rem;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 7rem;
}

.vacFilters {
	margin-left: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.vacSearch {
	margin-right: 1rem;
}


  