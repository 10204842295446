.MainLayout {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-rows: 6.5dvh minmax(85.5dvh, auto) 8dvh;
    /* box-sizing: border-box; */
}
.MainLayout > header {
    grid-row: 1;
}
.MainLayout > main {
    grid-row: 2;
    /* padding-top: 60px; */
}
.MainLayout > footer {
    grid-row: 3;
    width: 100%;
}
