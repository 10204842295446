.Page404 {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 116, 162)), url("../../../assets/pexels-oliver-sjöström-1098365.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 5rem;
    height: 100%;
}
  
  .button {
    font-weight: bolder;
    margin-top: 2rem;

  }
  
  .button:hover {
    background-color: #b68d3b;
  }
  