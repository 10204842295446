.SingleVacation {
	margin: auto;
}

.description{
    box-sizing: border-box;
}
.cardHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cardHeader > .price{
    margin-left: auto;
    margin-right: 5px;
}
