.EditVacation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
    width: 100vw;
    text-align: center;
}

.editVacationForm {
    margin-bottom: 15px;
    margin-top: 2rem;
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 1px 3px 10px #e9ecef;
}

.editVacationForm > .dateDiv {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form button {
    font-weight: bold;
    cursor: pointer;
} 

span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #0075A2;
}

.fileInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: 2px dashed #e9ecef;
    height: 200px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 1px 3px 10px #e9ecef;
}

.dateDiv {
    display: flex;
    flex-direction: column;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
}