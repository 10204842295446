.Register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70dvh; 
    width: 100vw;
    text-align: center;
}
.regForm {
    width: 25%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: 1px 3px 10px #e9ecef;
}

.form button {
    font-weight: bold;
    cursor: pointer;
} 

span {
    color: black;
    font-size: small;
}

