.Login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    text-align: center;
}

.logForm {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: 1px 3px 10px #e9ecef;
}
.form button {
    font-weight: bold;
    cursor: pointer;
}

span {
    color: black;
    font-size: small;
}
