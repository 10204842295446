.Reports {
	margin: auto;
    text-align: center;
}

.report-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
}

.MuiSwitch-thumb {
    color: #ffffff;
}

.recharts-wrapper {
    margin: auto;
    text-align: center;

}