.Footer {
    position: absolute;
    bottom: 0;
    grid-row: 3;
    width: 100%;
    height: 80px;
    background-color: #0075A2;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
}
