.UserNav {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.middle {
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    
}